import { Environment } from "./misc";

export const FOMO_RESOURCE_ADDRESS =
  process.env.REACT_APP_ENVIRONMENT === Environment.dev
    ? "resource_tdx_2_1tknxxxxxxxxxradxrdxxxxxxxxx009923554798xxxxxxxxxtfd2jc"
    : "resource_rdx1t5l954908vmg465pkj7j37z0fn4j33cdjt2g6czavjde406y4uxdy9";

export const OWNER_BADGE =
  "resource_rdx1t5a4neyeghh5tl8ucnn7nftwa6r0094g56hxq58m5ycufl6ghr2fa7";

export const STAKE_COMPONENT_ADDRESS =
  process.env.REACT_APP_ENVIRONMENT === Environment.dev
    ? "component_tdx_2_1czsey4e3lfuvwnzv7y4z2x0desweh2h4xnw833ua49uzx922p6flev"
    : "component_rdx1cr6lel0w3ktv0hww999uqpce5r2uzy24l5083dpg57n8xg66cerkcx";

export const ACCOUNT_HOLDING_OWNER_BADGE =
  "account_rdx128wxqwpqn0mtdc6dtyaf4sgkqtluxpc09rue7hu5eczvxsuzqc3dxx";

export const STAKED_FOMO_ADDRESS =
  process.env.REACT_APP_ENVIRONMENT === Environment.dev
    ? "resource_tdx_2_1ngzagguvds804chyh48jmrcd0q2fha0z56afr4tvx702ay24pkwhg7"
    : "resource_rdx1n2gm3tvlaqajcxzdafc8tz2w4k9h9nhf8cnwchle8u3jgle6mrfkuz";

export const DAPP_DEFINITION_ADDRESS =
  "account_rdx1288ae9j55j9hu3jamwd04w3hyj7tjyj9tle7jz5my8ntvsrw7jyj7k";

export const FOMO_AIRDROPS_VAULT_ADDRESS =
  "internal_keyvaluestore_rdx1kz7nuah65g6ad50lghxpp0nr7ckk0xw2y58drjakrz658tfaglt6u8";

export const OLD_STAKE_COMPONENT_ADDRESS =
  "component_rdx1czhz5y9eucherc7gvwhsjvdu6yeywh9pdtrulu5n76mt0x8llf536e";

export const OLD_STAKED_FOMO_ADDRESS =
  "resource_rdx1nf4djwvfsel2axsggh6k6x8a2cuycayn08c97z5u34uuegtdan5d7z";

export const OLD_FOMO_RESOURCE_ADDRESS =
  "resource_rdx1t4a42s786rs0nz6lv3skg2kvk94alxrpd5v73q9e7jrt33fsalcg0m";
