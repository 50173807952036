import React from "react";
import Button from "../../../Components/button";
import { dispatch, useSelector } from "../../../Store";
import { setIsSendAirdropGiftModalOpen } from "../../../Store/Reducers/session";

const GiftAirdropBtn = () => {
  const selectedUnstakeNft = useSelector(
    (state) => state.session.selectedUnstakeNft
  );
  return (
    <div className=" bg-[#0c0c0c] bg-opacity-60 px-[20px] mb-[5px] border-l-2 border-green-500 pb-3 rounded-b-lg">
      <Button
        onClick={() => dispatch(setIsSendAirdropGiftModalOpen(true))}
        className={"rounded-b-lg"}
        content={"Gift Airdrop Rewards 🎁"}
        disabled={selectedUnstakeNft.length === 0}
      />
    </div>
  );
};

export default GiftAirdropBtn;
