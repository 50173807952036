import { RadixNetwork } from "@radixdlt/radix-dapp-toolkit";

export const TOKENLIST_EXPIRE_PERIOD = 0.1 * 60 * 60 * 1000; // 10 minutes

export const Environment = {
  dev: "dev",
  prod: "prod",
};

export const MAX_AIRDROP_LIMIT = 350;

export const applicationName = "FOMO staking dApp";

export const networkId =
  process.env.REACT_APP_ENVIRONMENT === Environment.dev
    ? RadixNetwork.Stokenet
    : RadixNetwork.Mainnet;
