import { useState, useEffect, useRef } from "react";

import { fetchTransactions } from "../../../Utils/fetchers";
import AvatarGroup from "../../../Components/AvatarGroup";

const TransactionsSection = () => {
  const [transactions, setTransactions] = useState([]);
  const [cursor, setCursor] = useState(null);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const sentinelRef = useRef(null);

  const fetchData = async (newCursor = null) => {
    try {
      const data = await fetchTransactions(newCursor);
      const items = data.items.filter((item) =>
        item.receipt?.events?.some((event) => event.name === "AirdropEvent")
      );
      setTransactions((prev) => (newCursor ? [...prev, ...items] : items));
      setCursor(data.next_cursor);
      setHasMore(!!data.next_cursor);
    } catch (error) {
      console.error("Failed to fetch transactions:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMore && !loading) {
          fetchData(cursor);
        }
      },
      { threshold: 0.5 }
    );

    if (sentinelRef.current) {
      observer.observe(sentinelRef.current);
    }

    return () => observer.disconnect();
  }, [cursor, hasMore, loading]);

  if (loading) {
    return (
      <div className="bg-[#0c0c0c] bg-opacity-60 px-[20px] rounded-b-lg my-[5px] border-l-2 border-green-500 py-4">
        <div className="text-center">Loading airdrops...</div>
      </div>
    );
  }

  if (!transactions.length) {
    return (
      <div className="bg-[#0c0c0c] bg-opacity-60 px-[20px] rounded-b-lg my-[5px] border-l-2 border-green-500 py-4">
        <div className="text-center">No recent airdrops</div>
      </div>
    );
  }

  return (
    <div className="bg-[#0c0c0c] bg-opacity-60 px-[20px] rounded-b-lg my-[5px] border-l-2 border-green-500">
      <div className="py-4">
        <h3 className="text-lg font-semibold mb-3">Latest airdrops</h3>
        <div className="overflow-hidden">
          <table className="min-w-full table-auto">
            <thead>
              <tr className="border-b border-gray-700">
                <th className="py-2 text-left">Tx Hash</th>
                <th className="py-2 text-left">Recipient</th>
                <th className="py-2 text-left">Assets</th>
                <th className="py-2 text-right">Date</th>
              </tr>
            </thead>
            <tbody>
              {transactions.map((tx, index) => {
                const to =
                  tx.balance_changes.fungible_balance_changes?.[0]
                    ?.entity_address;
                const nbItems =
                  tx.balance_changes.fungible_balance_changes?.filter(
                    (change) => change.entity_address.startsWith("account_")
                  ).length;
                return (
                  <tr
                    key={tx.id || index}
                    className="border-b border-gray-700 last:border-0"
                  >
                    <td className="py-2 text-left">
                      <a
                        href={`https://radxplorer.com/transactions/${tx.intent_hash}`}
                        target="_blank"
                        className="text-green-500 hover:underline"
                        rel="noopener noreferrer"
                      >
                        {`${tx.intent_hash.slice(
                          0,
                          4
                        )}...${tx.intent_hash.slice(-4)}`}
                      </a>
                    </td>
                    <td className="py-2 text-left">
                      <a
                        href={`https://radxplorer.com/accounts/${to}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-sm text-gray-400"
                      >
                        {`${to.slice(0, 4)}...${to.slice(-4)}`}
                      </a>
                    </td>
                    <td className="py-2 flex flex-col items-start text-right">
                      <div className="text-left">
                        <div>{nbItems} assets</div>
                      </div>
                      <AvatarGroup
                        images={tx.balance_changes.fungible_balance_changes
                          .filter((change) =>
                            change.entity_address.startsWith("account_")
                          )
                          .map((change) => change.resource_address)}
                      />
                    </td>
                    <td className="py-2 text-right">
                      {new Date(tx.confirmed_at).toLocaleString()}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div ref={sentinelRef} className="h-4">
          {loading && hasMore && (
            <div className="text-center py-2">Loading more...</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TransactionsSection;
