import { Button, Modal } from "flowbite-react";
import React from "react";
import { formatTokenAmount } from "../../Utils/formatter";

const AirdropConfirmModal = ({
  isModalOpen,
  onClose,
  onAccept,
  amount,
  tokenSymbol,
}) => {
  return (
    <Modal
      show={isModalOpen}
      size="md"
      onClose={onClose}
      popup
      theme={{
        content: {
          base: "relative w-full p-4 h-auto max-w-md",
          inner:
            "relative flex max-h-[90dvh] flex-col rounded-lg bg-[#232325] bg-opacity-30 backdrop-blur-lg border border-gray-500",
        },
      }}
      className="backdrop-blur-sm"
    >
      <Modal.Header />
      <Modal.Body>
        <div className="text-center ">
          <h3 className="mb-5 text-lg font-normal text-white">
            Are you sure you want to airdrop{" "}
            <strong>
              {formatTokenAmount(amount)} ${tokenSymbol}
            </strong>{" "}
            to Fomo Stakers?
          </h3>
          <div className="flex justify-center gap-4">
            <Button color="success" onClick={onAccept}>
              {"Yes, I'm sure"}
            </Button>
            <Button color="failure" onClick={onClose}>
              No, cancel
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AirdropConfirmModal;
