import {
  FOMO_RESOURCE_ADDRESS,
  OLD_FOMO_RESOURCE_ADDRESS,
  OLD_STAKED_FOMO_ADDRESS,
  STAKED_FOMO_ADDRESS,
} from "../Constants/addresses";
import { gatewayApi } from "../Constants/endpoints";

export const getAccountBalance = async (address) => {
  let balance = "0";
  let oldBalance = "0";
  let nftarray;
  let oldNftArray;

  const result = await gatewayApi.state.getEntityDetailsVaultAggregated(
    address,
    {
      explicitMetadata: ["name", "symbol", "icon_url"],
    }
  );

  const { fungible_resources, non_fungible_resources } = result;

  let resource, oldResource, nftresource, oldNftResource;

  fungible_resources.items.forEach((item) => {
    if (item.resource_address === FOMO_RESOURCE_ADDRESS) {
      resource = item;
    } else if (item.resource_address === OLD_FOMO_RESOURCE_ADDRESS) {
      oldResource = item;
    }
  });

  non_fungible_resources.items.forEach((item) => {
    if (item.resource_address === STAKED_FOMO_ADDRESS) {
      nftresource = item;
    } else if (item.resource_address === OLD_STAKED_FOMO_ADDRESS) {
      oldNftResource = item;
    }
  });
  if (resource && resource.vaults.items.length) {
    balance = resource.vaults.items[0].amount;
  }
  if (oldResource && oldResource.vaults.items.length) {
    oldBalance = oldResource.vaults.items[0].amount;
  }
  if (nftresource && nftresource.vaults.items.length) {
    nftarray = nftresource.vaults.items[0].items;
  }
  if (oldNftResource && oldNftResource.vaults.items.length) {
    oldNftArray = oldNftResource.vaults.items[0].items;
  }
  return [
    balance,
    nftarray,
    extractTokenInfo(result.fungible_resources.items),
    oldBalance,
    oldNftArray,
  ];
};

export const extractTokenInfo = (items) => {
  const extractedInfo = [];
  items.forEach((item) => {
    const address = item.resource_address;
    const name = item?.explicit_metadata?.items?.[0]?.value?.typed?.value;
    const symbol = item?.explicit_metadata?.items?.[1]?.value?.typed?.value;
    const icon_url =
      item?.explicit_metadata?.items?.[2]?.value?.typed?.value ??
      "/images/logo.jpg";
    const balance = item.vaults.items[0].amount;

    if (item?.explicit_metadata?.items.length > 2 && +balance > 0) {
      extractedInfo.push({
        address,
        name,
        symbol,
        icon_url,
        balance,
      });
    }
  });

  return extractedInfo;
};

export const extractFomoNftInfo = (nftsData, forOldNft = false) => {
  const extractedInfo = [];

  nftsData.forEach((singleNftData) => {
    const thisNftData = {};

    singleNftData?.data?.programmatic_json?.fields?.forEach((field) => {
      thisNftData[field.field_name] = field.value;
    });

    if (forOldNft) {
      thisNftData.id = singleNftData.non_fungible_id.replace(/#/g, "");
    }

    // check if the nft has the required fields then push it to extractInfo array
    // required fields are id, amount_staked, nftImage, stake_share
    if (
      (forOldNft ? true : thisNftData.id) &&
      thisNftData.amount_staked &&
      thisNftData.stake_share
    ) {
      thisNftData.nftImage = "/images/stake-fomo-nft.png";
      extractedInfo.push(thisNftData);
    }
  });

  return extractedInfo;
};

export const getNFTData = async (nftaddress, indexArray) => {
  try {
    const result = await gatewayApi.state.getNonFungibleData(
      nftaddress,
      indexArray
    );
    return result;
  } catch (error) {
    console.error(error);
    return [];
  }
};
