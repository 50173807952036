import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tokensLoading: false,
  tokensFetching: false,
};

const loadings = createSlice({
  name: "loadings",
  initialState,
  reducers: {
    setTokensFetching(state, action) {
      state.tokensLoading = action.payload;
      state.tokensFetching = action.payload;
    },
    setTokensLoading(state, action) {
      state.tokensLoading = action.payload;
    },
  },
});

export default loadings.reducer;

export const { setTokensFetching, setTokensLoading } = loadings.actions;
