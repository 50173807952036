import { GatewayApiClient } from "@radixdlt/babylon-gateway-api-sdk";
import { applicationName, Environment, networkId } from "./misc";
import { DAPP_DEFINITION_ADDRESS } from "./addresses";

export const HIT_SERVER_URL =
  process.env.REACT_APP_ENVIRONMENT === Environment.dev
    ? "http://localhost:3000"
    : "https://bot.addix.meme";

export const gatewayApi = GatewayApiClient.initialize({
  networkId,
  applicationName,
  applicationVersion: "1.0.0",
  applicationDappDefinitionAddress: DAPP_DEFINITION_ADDRESS,
});

export const radixDashboardBaseUrl = "https://dashboard.radixdlt.com";
