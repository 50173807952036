import { Tooltip } from "flowbite-react";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "../../../Store";
import { formatTokenAmount } from "../../../Utils/formatter";
import { validateDecimalPlaces } from "../../../Utils/helpers";
import { Stake } from "../../../Utils/txSenders";
import Button from "../../../Components/button";

const StakeSection = () => {
  const [inputStakingAmount, setInputAmount] = useState("0");
  const successTx = useSelector((state) => state.session.successTx);
  const fomoBalance = useSelector((state) => state.session.fomoBalance);
  const walletAddress = useSelector((state) => state.app.walletAddress);

  const handleStakeInput = (e) => {
    if (validateDecimalPlaces(e.target.value, 18) || e.target.value === "") {
      setInputAmount(e.target.value);
    }
  };
  const setMaxStakingAmount = () => {
    setInputAmount(fomoBalance);
  };

  const isInsufficientBalance = useMemo(
    () => +inputStakingAmount > +fomoBalance,
    [fomoBalance, inputStakingAmount]
  );

  const isStakeButtonDisabled = useMemo(
    () => isInsufficientBalance || +inputStakingAmount === 0,
    [isInsufficientBalance, inputStakingAmount]
  );

  useEffect(() => {
    if (Boolean(successTx)) {
      setInputAmount("0");
    }
  }, [successTx]);

  return (
    <div className="bg-[#0c0c0c] bg-opacity-60 px-[20px] my-[5px] py-[15px] rounded-b-lg border-l-2 border-green-500">
      <div className="flex text-[12px]">
        <div className="text-gray-400">Balance:&nbsp;</div>
        <Tooltip
          content={fomoBalance}
          className="bg-[#232325] bg-opacity-90 backdrop-blur-lg"
          arrow={false}
        >
          <div className="cursor-pointer">
            {formatTokenAmount(+fomoBalance)}
          </div>
        </Tooltip>
        <div className="text-gray-400">&nbsp; FOMO</div>
      </div>
      <div className="relative">
        <input
          type="number"
          placeholder="Input Amount"
          min={1}
          value={inputStakingAmount}
          onChange={handleStakeInput}
          className={`bg-[#232325] bg-opacity-60 p-[10px] my-[5px] outline-none w-full rounded-t-lg ${
            isInsufficientBalance ? "border-red-500" : ""
          }`}
        />
        <div
          onClick={setMaxStakingAmount}
          className="absolute right-[35px] top-[15px] hover:cursor-pointer"
        >
          MAX
        </div>
      </div>
      <div className={"flex text-[12px] items-end "}>
        {isInsufficientBalance ? (
          <>
            <div className="text-red-500">Insufficient Balance</div>
          </>
        ) : (
          <>
            <div className="text-gray-400">You are staking &nbsp;</div>
            <div>
              {+inputStakingAmount === 0
                ? "0"
                : formatTokenAmount(inputStakingAmount)}
            </div>
            <div className="text-gray-400">&nbsp; FOMO</div>
          </>
        )}
      </div>
      <Button
        onClick={() => Stake(inputStakingAmount, walletAddress)}
        className={"rounded-b-lg my-[5px]"}
        disabled={isStakeButtonDisabled}
        content={"STAKE"}
      />
      <div className="flex items-center justify-center mt-2">
        <img src="images/icon/info.svg" className="w-5" alt="info" />
        <p className="text-sm ml-1 ">
          The more you stake, the higher your share of FOMO rewards/airdrops
        </p>
      </div>
    </div>
  );
};

export default StakeSection;
