import {
  DataRequestBuilder,
  RadixDappToolkit,
} from "@radixdlt/radix-dapp-toolkit";
import { DAPP_DEFINITION_ADDRESS } from "./Constants/addresses";
import { store } from "./Store";
import { setWalletData } from "./Store/Reducers/app";
import { applicationName, networkId } from "./Constants/misc";

let rdtInstance = null;
export function getRdt() {
  return rdtInstance;
}
function setRdt(rdt) {
  rdtInstance = rdt;
}

let subs = [];

export function initializeSubscriptions() {
  rdtInstance = RadixDappToolkit({
    dAppDefinitionAddress: DAPP_DEFINITION_ADDRESS,
    networkId,
    applicationName,
    applicationVersion: "1.0.0",
  });
  rdtInstance.walletApi.setRequestData(
    DataRequestBuilder.accounts().exactly(1)
  );
  subs.push(
    rdtInstance.walletApi.walletData$.subscribe((walletData) => {
      const data = JSON.parse(JSON.stringify(walletData));
      console.log("data", data);
      store.dispatch(setWalletData(data));
    })
  );
  setRdt(rdtInstance);
  // TODO: "black" on the light theme
  // rdtInstance.buttonApi.setTheme("white");
}

export function unsubscribeAll() {
  subs.forEach((sub) => {
    sub.unsubscribe();
  });
  subs = [];
}
