import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { MagnifyingGlass } from "react-loader-spinner";
import { useParams } from "react-router-dom";

import { HIT_SERVER_URL } from "../../Constants/endpoints";
import TokenShareTile from "./Components/tokenShareTile";
import { useSelector } from "../../Store";
import NftSelectionModal from "../../Components/Modals/nftSelection";
import { formatTokenAmount } from "../../Utils/formatter";

const NFTData = () => {
  const { id } = useParams();
  const [typedNftId, setTypedNftId] = useState(id || "");
  const [nftData, setNftData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const userFomoNfts = useSelector((state) => state.session.userFomoNfts);

  const [selectedNft, setSelectedNft] = useState([]);

  const [isNftSelectionModalOpen, setIsNftSelectionModalOpen] = useState(false);

  useEffect(() => {
    if (id) {
      searchNFT(id);
    }
  }, [id]);

  const searchNFT = async (urlId) => {
    const idToSearch =
      typedNftId !== ""
        ? typedNftId
        : selectedNft.length > 0
        ? selectedNft[0].id
        : urlId;
    if (!isLoading && idToSearch) {
      try {
        setIsLoading(true);
        const { data, status } = await axios.post(
          `${HIT_SERVER_URL}/fomo/get-fomo-data`,
          { id: idToSearch }
        );

        if (status === 201 && Object.values(data).length > 0) {
          setIsError(false);
          setNftData(Object.values(data));
        } else {
          setIsError(true);
          setNftData([]);
        }
      } catch (error) {
        setIsError(true);
        setNftData([]);
      }
      setIsLoading(false);
    }
  };

  const renderNftData = useCallback(() => {
    return nftData.map((data) => (
      <TokenShareTile tokenData={data} key={data.resource_address} />
    ));
  }, [nftData]);

  const handleSelectChange = (event) => {
    setTypedNftId("");
    setIsNftSelectionModalOpen(false);
  };

  const handleSearchChange = (e) => {
    setTypedNftId(e.target.value);
    if (selectedNft.length > 0) {
      setSelectedNft([]);
    }
  };

  return (
    <div
      style={{ height: "calc(100vh - 125px)" }}
      className="pt-[50px] flex flex-col items-center"
    >
      <div className="flex-1 min-h-[100%] flex flex-col items-center">
        {userFomoNfts.length > 0 && (
          <>
            <div className="flex text-left">
              <div>
                <div
                  onClick={() => setIsNftSelectionModalOpen(true)}
                  className="border border-gray-500 bg-[#232325] bg-opacity-60 p-[10px]  outline-none w-full rounded-lg text-white cursor-pointer hover:border-green-500"
                >
                  {selectedNft.length > 0 ? (
                    <div className="flex items-center">
                      <img
                        className="rounded-full w-5 h-5 bg-cover bg-center bg-no-repeat"
                        src={selectedNft[0].nftImage}
                        alt="icon"
                        onError={(e) => (e.target.src = "/images/logo.jpg")}
                      />
                      <p className="ml-2">id:{selectedNft[0].id}</p>
                      <p className="ml-2" title={selectedNft[0].amount_staked}>
                        ({formatTokenAmount(+selectedNft[0].amount_staked)}{" "}
                        FOMO)
                      </p>
                    </div>
                  ) : (
                    <div className="flex items-center justify-between cursor-pointer">
                      {"Select your FOMO NFT"}{" "}
                      <img
                        src="images/icon/chevron-down.svg"
                        className="text-gray-500"
                        alt="info"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <p className="font-semibold text-white my-2">OR</p>
          </>
        )}
        <div className="flex items-center flex-col justify-center w-full">
          <input
            placeholder="Search by NFT id#"
            type="number"
            value={typedNftId}
            className="rounded-lg w-full bg-[#232325] bg-opacity-60 text-white placeholder:text-white "
            onChange={handleSearchChange}
          />
          <div
            onClick={searchNFT}
            className="w-full bg-green-500 p-[10px] text-center my-[5px] rounded-lg font-bold hover:cursor-pointer hover:bg-opacity-80"
          >
            Search NFT Rewards
          </div>
        </div>
        <div
          className={"flex items-center flex-1 flex-col w-[90vw] max-w-[350px] gap-3 overflow-auto ".concat(
            isError || isLoading ? "justify-center" : "justify-start py-3"
          )}
        >
          {isLoading ? (
            <div className="self-center">
              <MagnifyingGlass
                visible={true}
                height="80"
                width="80"
                ariaLabel="magnifying-glass-loading"
                wrapperStyle={{}}
                wrapperClass="magnifying-glass-wrapper"
                glassColor="#c0efff"
                color="#0d9f6e"
              />
              <p className="text-white">Searching...</p>
            </div>
          ) : nftData.length > 0 ? (
            renderNftData()
          ) : isError ? (
            <div className="self-center">
              <p className="text-white">No NFT data found :(</p>
            </div>
          ) : null}
        </div>
      </div>

      <NftSelectionModal
        isModalOpen={isNftSelectionModalOpen}
        onClose={() => setIsNftSelectionModalOpen(false)}
        nftsArray={userFomoNfts}
        setSelectedNfts={setSelectedNft}
        selectedNfts={selectedNft}
        onNftSelect={handleSelectChange}
      />
    </div>
  );
};

export default NFTData;
