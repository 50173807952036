import { Modal } from "flowbite-react";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { MagnifyingGlass } from "react-loader-spinner";

import CardsGroup from "../cardsGroup";
import { HIT_SERVER_URL } from "../../Constants/endpoints";

const AirdropSelectionModal = ({
  idToSearch,
  isModalOpen,
  onClose,
  selectedCards,
  setSelectedCards,
  onCardSelect,
}) => {
  const [loading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [nftData, setNftData] = useState([]);

  const fetchNFTdata = async (idToSearch) => {
    try {
      setIsLoading(true);
      const { data, status } = await axios.post(
        `${HIT_SERVER_URL}/fomo/get-fomo-data`,
        { id: idToSearch }
      );

      if (status === 201 && Object.values(data).length > 0) {
        setIsError(false);
        setNftData(Object.values(data));
      } else {
        setIsError(true);
        setNftData([]);
      }
    } catch (error) {
      setIsError(true);
      setNftData([]);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (idToSearch) {
      fetchNFTdata(idToSearch);
    }
  }, [idToSearch]);

  return (
    <Modal
      show={isModalOpen}
      size="md"
      onClose={onClose}
      popup
      theme={{
        content: {
          base: "relative w-full px-2 h-auto !max-w-xl ",
          inner:
            "relative flex max-h-[70dvh] flex-col rounded-lg bg-[#232325] bg-opacity-30 backdrop-blur-lg border border-gray-500",
        },
      }}
      className="backdrop-blur-sm"
    >
      <Modal.Header />
      <Modal.Body>
        <div className="w-full">
          <p className="text-white">You can select multiple tokens</p>
          <div className="flex flex-col gap-4">
            {loading ? (
              <div className="self-center">
                <MagnifyingGlass
                  visible={true}
                  height="80"
                  width="80"
                  ariaLabel="magnifying-glass-loading"
                  wrapperStyle={{}}
                  wrapperClass="magnifying-glass-wrapper"
                  glassColor="#c0efff"
                  color="#0d9f6e"
                />
                <p className="text-white">Searching...</p>
              </div>
            ) : nftData.length > 0 ? (
              <CardsGroup
                cardsArray={nftData}
                heading_key="symbol"
                img_key="icon_url"
                value_key="share"
                tokenAddress_key="resource_address"
                isBalance
                selectedCards={selectedCards}
                setSelectedCards={setSelectedCards}
                enableCardSelection
                onCardSelect={onCardSelect}
                enableMultipleSelection
                limitSelection={20}
              />
            ) : isError ? (
              <div className="self-center">
                <p className="text-white">No NFT data found :(</p>
              </div>
            ) : null}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AirdropSelectionModal;
