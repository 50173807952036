import React, { useEffect } from "react";
import { dispatch, useSelector } from "../Store";
import {
  setFomoBalance,
  setUserFomoNfts,
  setUserTokens,
} from "../Store/Reducers/session";
import { getDataWallet, getLatestAirdrops } from "../Utils/fetchers";
import { cssTransition, toast } from "react-toastify";
import { AirdropAlertToast } from "./toasts";
import TokenCacheService from "../Classes/tokenCache";
import { isObjectEmpty } from "../Utils/helpers";

const buzzing = cssTransition({
  enter: "buzz-in",
  exit: "buzz-out",
});

const Listeners = () => {
  const successTx = useSelector((state) => state.session.successTx);
  const walletAddress = useSelector((state) => state.app.walletAddress);
  const tokensLoading = useSelector((state) => state.loadings.tokensLoading);
  const latestAirdrops = useSelector((state) => state.session.latestAirdrops);

  useEffect(() => {
    if (walletAddress) {
      getDataWallet(walletAddress);
    } else {
      dispatch(setUserFomoNfts([]));
      dispatch(setFomoBalance(0));
      dispatch(setUserTokens([]));
    }
  }, [walletAddress, successTx]);

  useEffect(() => {
    if (
      tokensLoading === false &&
      Boolean(TokenCacheService.allTokens) &&
      !isObjectEmpty(TokenCacheService.allTokens)
    ) {
      getLatestAirdrops();
    }
  }, [tokensLoading]);

  useEffect(() => {
    if (latestAirdrops.length > 0) {
      let currentIndex = 0;

      const showAirdropToast = () => {
        const airdrop = latestAirdrops[currentIndex];
        toast.dark(
          <AirdropAlertToast
            amount={airdrop.airdropAmount}
            tokenName={airdrop.symbol}
            logoUrl={airdrop.icon_url}
            infoUrl={airdrop.info_url}
            worthInUSD={airdrop.worthInUSD}
          />,
          {
            transition: buzzing,
            position: "top-center",
            draggable: false,
            pauseOnHover: false,
            isLoading: false,
            pauseOnFocusLoss: true,
            progress: false,
            autoClose: 7000,
            closeOnClick: true,
            hideProgressBar: true,
            bodyClassName: "",
          }
        );

        currentIndex = (currentIndex + 1) % latestAirdrops.length;
      };

      const intervalId = setInterval(showAirdropToast, 11000); // 11 seconds interval

      return () => clearInterval(intervalId); // Cleanup on unmount
    }
  }, [latestAirdrops]);

  return <></>;
};

export default Listeners;
