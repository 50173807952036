import { useNavigate } from "react-router-dom";
import { Dropdown } from "flowbite-react";

export const Header = () => {
  const navigate = useNavigate();
  return (
    <>
      <main className="w-full bg-black overflow-visible">
        <div className="mx-auto text-white flex items-center justify-between py-[20px] px-6 md:px-14">
          <div onClick={() => navigate("/")} className="flex cursor-pointer">
            <img
              className=" rounded-[50%]"
              src="images/logo.jpg"
              width={"40px"}
              alt="logo"
            />
            <div className="py-[5px] px-[10px]  text-[20px] md:block hidden font-bold text-[#1ED760]">
              FOMO ON XRD
            </div>
          </div>
          <div className="flex items-center justify-end gap-6">
            <div>
              <Dropdown
                placement="auto"
                label="Socials"
                inline
                theme={{
                  inlineWrapper:
                    "flex items-center cursor-pointer hover:text-green-500 font-semibold text-lg",
                  floating: {
                    item: {
                      base: "flex items-center text-white hover:text-green-500 hover:bg-transparent gap-3 px-4 py-2 font-semibold w-full",
                    },
                  },
                }}
                className="bg-black bg-opacity-90 backdrop-blur-lg border border-gray-500"
              >
                <Dropdown.Item
                  onClick={() => window.open("https://twitter.com/fomoxrd")}
                >
                  <img
                    src="images/icon/x.svg"
                    alt="telegram"
                    className="w-5 cursor-pointer"
                  />
                  Twitter / X{" "}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => window.open("https://t.me/fomoradix")}
                >
                  <img
                    src="images/icon/telegram.svg"
                    alt="x"
                    className="w-5 cursor-pointer"
                  />
                  Telegram{" "}
                </Dropdown.Item>
              </Dropdown>
            </div>
            <radix-connect-button />
          </div>
        </div>
        <div className="text-white border-t-[1px] py-0.5 bg-green-500 bg-opacity-35 font-semibold">
          By using this site you agree to{" "}
          <span
            className="underline cursor-pointer"
            onClick={() =>
              window.open("https://sites.google.com/view/fomostaking/home")
            }
          >
            terms and conditions
          </span>
        </div>
      </main>
    </>
  );
};
