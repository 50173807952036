import { useEffect } from "react";
import { fetchDetails } from "../Utils/fetchers";
import { useState } from "react";

const AvatarGroup = ({ images, max = 4 }) => {
  const displayImages = images.slice(0, max);
  const remaining = images.length - max;
  const [imagesData, setImagesData] = useState([]);
  const [names, setNames] = useState([]);

  useEffect(() => {
    fetchDetails(displayImages).then((data) => {
      setImagesData(
        data?.items?.map(
          (item) =>
            item.metadata.items.find((i) => i.key === "icon_url")?.value.typed
              .value
        )
      );
      setNames(
        data?.items?.map(
          (item) => item.metadata.items.find((i) => i.key === "name")?.value
        )
      );
    });
  }, [images]);

  return (
    <div className="flex -space-x-2">
      {imagesData.map((image, index) => (
        <div key={index} className="relative group">
          <img
            src={image}
            alt={`Avatar ${index + 1}`}
            className="w-6 h-6 rounded-full border-2 border-gray-800 object-cover"
          />
          <div className="absolute invisible group-hover:visible z-10 -translate-x-1/2 left-1/2 mt-2">
            <div className="bg-black text-white text-xs rounded-md px-2 py-1 whitespace-nowrap">
              {names[index]?.typed?.value || names[index]?.value || "Unknown"}
            </div>
          </div>
        </div>
      ))}
      {remaining > 0 && (
        <div className="w-6 h-6 z-10 rounded-full bg-gray-700 border-2 border-gray-800 flex items-center justify-center text-xs text-white">
          +{remaining}
        </div>
      )}
    </div>
  );
};

export default AvatarGroup;
