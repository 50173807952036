import { Tooltip } from "flowbite-react";
import React from "react";
import { useSelector } from "../../../Store";
import { formatTokenAmount } from "../../../Utils/formatter";

const StakeInfo = () => {
  const userStakedFomo = useSelector((state) => state.session.userStakedFomo);
  return (
    <div className="w-1/2 flex items-center justify-end">
      <Tooltip
        content={userStakedFomo}
        className="bg-[#232325] bg-opacity-90 backdrop-blur-lg"
        arrow={false}
      >
        {formatTokenAmount(userStakedFomo)}
      </Tooltip>
    </div>
  );
};

export default StakeInfo;
