import { Modal } from "flowbite-react";
import React, { useMemo, useState } from "react";

import { dispatch, useSelector } from "../../Store";
import { setIsSendAirdropGiftModalOpen } from "../../Store/Reducers/session";
import AirdropSelectionModal from "./airdropSelection";
import Button from "../button";
import { sendGiftWhileUnstakeOrClaim } from "../../Utils/txSenders";
import { FOMO_RESOURCE_ADDRESS } from "../../Constants/addresses";

const SendAirdropGift = () => {
  const [isAirdropSelectionModalOpen, setIsAirdropSelectionModalOpen] =
    useState(false);
  const [selectedTokens, setSelectedTokens] = useState([]);
  const [giftAddress, setGiftAddress] = useState("");

  const isSendAirdropGiftModalOpen = useSelector(
    (state) => state.session.isSendAirdropGiftModalOpen
  );
  const selectedUnstakeNft = useSelector(
    (state) => state.session.selectedUnstakeNft
  );
  const walletAddress = useSelector((state) => state.app.walletAddress);

  const onClose = () => {
    dispatch(setIsSendAirdropGiftModalOpen(false));
  };

  const allReadyForGift = useMemo(
    () => selectedTokens.length > 0 && giftAddress.length > 0,
    [giftAddress, selectedTokens.length]
  );

  const handleSendGift = (isClaim) => {
    sendGiftWhileUnstakeOrClaim(
      walletAddress,
      selectedUnstakeNft[0]?.id,
      giftAddress,
      isClaim,
      selectedTokens.map((token) => token.resource_address)
    );
  };

  const isFomoTokenSelected = useMemo(() => {
    return selectedTokens.some(
      (token) => token.resource_address === FOMO_RESOURCE_ADDRESS
    );
  }, [selectedTokens]);

  return (
    <>
      <Modal
        show={isSendAirdropGiftModalOpen}
        size="md"
        onClose={onClose}
        popup
        theme={{
          content: {
            base: "relative w-full px-2 h-auto !max-w-xl ",
            inner:
              "relative flex max-h-[70dvh] flex-col rounded-lg bg-[#232325] bg-opacity-30 backdrop-blur-lg border border-gray-500",
          },
        }}
        className="backdrop-blur-sm"
      >
        <Modal.Header />
        <Modal.Body>
          <div className="flex flex-col ">
            <p className="text-white text-lg font-bold text-center w-full">
              🎁 Gift Airdrop Rewards 🎁
            </p>
            <p className="text-white font-semibold">STEP 1</p>
            <input
              type="text"
              placeholder="Enter receiver address"
              value={giftAddress}
              onChange={(e) => setGiftAddress(e.target.value)}
              className="w-full p-2 rounded bg-[#232325] bg-opacity-60 text-white mt-1 mb-3"
            />
            <p className="text-white font-semibold mb-1">STEP 2</p>
            <Button
              onClick={() => setIsAirdropSelectionModalOpen(true)}
              content={"Select Tokens"}
              className="rounded text-white"
              disabled={!giftAddress.length}
            />

            <div className="flex items-center justify-center flex-wrap mt-2 border mb-3 border-gray-500 rounded py-3 px-2">
              {selectedTokens.length === 0 ? (
                <p className="text-gray-500">No Tokens Selected</p>
              ) : (
                selectedTokens.map((token, index) => (
                  <div
                    key={index}
                    className="flex items-center cursor-pointer border-gray-500 bg-[#232325] bg-opacity-80 border text-white rounded-full px-3 py-1 m-1"
                  >
                    <img
                      src={token.icon_url}
                      alt={token.symbol}
                      className="w-6 h-6 rounded-full mr-2"
                    />
                    <span>{token.symbol}</span>
                  </div>
                ))
              )}
            </div>
            <p className="text-white font-semibold mb-1">STEP 3</p>
            <Button
              onClick={() => handleSendGift(true)}
              content={"Claim and Send Gift"}
              className="rounded text-white"
              disabled={!allReadyForGift || isFomoTokenSelected}
            />
            {isFomoTokenSelected && (
              <Note
                text="You cannot gift FOMO tokens with claim rewards."
                className="mt-1"
              />
            )}
            <p className="font-bold w-full text-center text-white my-2">OR</p>
            <Button
              onClick={() => handleSendGift(false)}
              content={"Unstake and Send Gift"}
              className="rounded text-white"
              disabled={!allReadyForGift}
            />
          </div>
          <p className="text-white font-semibold w-full text-center my-3">
            Read Carefully!
          </p>
          <Note
            text="Only selected tokens will be sent to the receiver, remaining
              tokens will be sent to your wallet."
          />
          <Note
            text="Avoid selecting more than 50 tokens to prevent high transaction
              fees and failure."
          />
          <Note
            text="This is a complex transaction, radix wallet might not be able to
              show a simplied transaction preview."
          />
        </Modal.Body>
      </Modal>

      <AirdropSelectionModal
        idToSearch={selectedUnstakeNft[0]?.id}
        isModalOpen={isAirdropSelectionModalOpen}
        onClose={() => setIsAirdropSelectionModalOpen(false)}
        selectedCards={selectedTokens}
        setSelectedCards={setSelectedTokens}
      />
    </>
  );
};

const Note = ({ text, className }) => {
  return (
    <div
      className={"flex items-start justify-start mb-1 text-white " + className}
    >
      <img src="images/icon/info.svg" className="w-4 mr-1" alt="info" />
      <p className="text-sm font-normal -mt-0.5">{text}</p>
    </div>
  );
};

export default SendAirdropGift;
