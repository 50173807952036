import Decimal from "decimal.js";

import {
  extractFomoNftInfo,
  getAccountBalance,
  getNFTData,
} from "../Components/useGetEntityDetails";
import {
  FOMO_AIRDROPS_VAULT_ADDRESS,
  OLD_STAKED_FOMO_ADDRESS,
  STAKE_COMPONENT_ADDRESS,
  STAKED_FOMO_ADDRESS,
} from "../Constants/addresses";
import { gatewayApi } from "../Constants/endpoints";
import { dispatch } from "../Store";
import {
  setFomoBalance,
  setLatestAirdrops,
  setOldFomoBalance,
  setOldUserFomoNfts,
  setUserFomoNfts,
  setUserTokens,
} from "../Store/Reducers/session";
import { fetch_KeyValueStoreData_in_chunks } from "./helpers";
import TokenCacheService from "../Classes/tokenCache";
import axios from "axios";

export const getDataWallet = async (address) => {
  const [balance, nftarray, userTokens, oldBalance, oldNftArray] =
    await getAccountBalance(address);
  if (nftarray !== undefined && nftarray.length > 0) {
    const nftdata = extractFomoNftInfo(
      await getNFTData(STAKED_FOMO_ADDRESS, nftarray)
    );
    dispatch(setUserFomoNfts(nftdata));
  } else {
    dispatch(setUserFomoNfts([]));
  }
  dispatch(setUserTokens(userTokens ?? []));
  dispatch(setFomoBalance(balance));
  dispatch(setOldFomoBalance(oldBalance));
  // do the same for old resources
  if (oldNftArray !== undefined && oldNftArray.length > 0) {
    const oldNftData = extractFomoNftInfo(
      await getNFTData(OLD_STAKED_FOMO_ADDRESS, oldNftArray),
      true
    );
    dispatch(setOldUserFomoNfts(oldNftData));
  } else {
    dispatch(setOldUserFomoNfts([]));
  }
};

export const baseUrl = "https://mainnet.radixdlt.com/";
// TESTNET
//export const baseUrl = 'https://babylon-stokenet-gateway.radixdlt.com/';

export async function fetchDetails(assets) {
  const data = await axios
    .post(`${baseUrl}state/entity/details`, {
      addresses: assets,
    })
    .then((response) => response.data)
    .catch((err) => {
      return Promise.reject(err);
    });

  return data;
}

export async function fetchTransactions(cursor) {
  const data = await axios
    .post(`${baseUrl}stream/transactions`, {
      cursor: cursor,
      limit_per_page: 100,
      affected_global_entities_filter: [STAKE_COMPONENT_ADDRESS],
      event_filter: {
        event: "Withdrawal",
      },
      opt_ins: {
        balance_changes: true,
        receipt_output: true,
        receipt_events: true,
      },
    })
    .then((response) => response.data)
    .catch((err) => {
      return Promise.reject(err);
    });

  return data;
}

export const getLatestAirdrops = async (
  next_cursor = null,
  ledger_state = undefined
) => {
  const raw_hexes_to_query = [];
  const airdrop_ids_with_state_version = [];
  const coins_resource_addresses = new Set([]);
  const airdropData = {};
  const coins_metadata = {};
  const totalStakeShareWhileAirdrop = {};

  // get airdrop keys
  const {
    items,
    next_cursor: new_next_cursor,
    ledger_state: new_ledger_state,
  } = await gatewayApi.state.innerClient.keyValueStoreKeys({
    stateKeyValueStoreKeysRequest: {
      key_value_store_address: FOMO_AIRDROPS_VAULT_ADDRESS,
      limit_per_page: 100,
      cursor: next_cursor,
      at_ledger_state:
        ledger_state === undefined
          ? undefined
          : { state_version: ledger_state.state_version },
    },
  });

  // extract airdrop id and state version
  items.forEach((item, i) => {
    if (item.key.programmatic_json.kind === "U64") {
      raw_hexes_to_query.push({ key_hex: item.key.raw_hex });
      airdrop_ids_with_state_version.push({
        airdrop_id: item.key.programmatic_json.value,
        state_version: item.last_updated_at_state_version,
      });
    }
  });

  // fetch key value store airdrop data of the extracted raw hexes
  const airdrops_data_res = (
    await fetch_KeyValueStoreData_in_chunks(
      FOMO_AIRDROPS_VAULT_ADDRESS,
      raw_hexes_to_query,
      gatewayApi
    )
  )
    .map((data) => data.entries)
    .flat();

  // extract token address and per share amount from each airdrop data
  airdrops_data_res.forEach((airdrop_info) => {
    const tokenAddress = airdrop_info.value.programmatic_json.fields[0].value;
    coins_resource_addresses.add(tokenAddress);
    airdropData[airdrop_info.key.programmatic_json.value] = {
      id: airdrop_info.key.programmatic_json.value,
      tokenAddress,
      perShare: airdrop_info.value.programmatic_json.fields[1].value,
    };
  });

  // get airdrop coins metadata
  const all_coins_entity_data =
    await gatewayApi.state.getEntityDetailsVaultAggregated(
      Array.from(coins_resource_addresses),
      { explicitMetadata: ["name", "symbol", "icon_url"] }
    );

  // extract name, symbol and icon_url from coins metadata
  all_coins_entity_data.forEach((coinInfo) => {
    const address = coinInfo.address;
    let name = "";
    let symbol = "";
    let icon_url = "";
    let info_url = "";

    coinInfo.metadata.items.forEach((metadata) => {
      const metadata_value = metadata.value.programmatic_json.fields[0].value;
      if (metadata.key === "name") {
        name = metadata_value;
      } else if (metadata.key === "symbol") {
        symbol = metadata_value;
      } else if (metadata.key === "icon_url") {
        icon_url = metadata_value;
      } else if (metadata.key === "info_url") {
        info_url = metadata_value;
      }
    });

    coins_metadata[address] = {
      name,
      symbol,
      icon_url,
      info_url,
    };
  });

  // get the total stake share of component at the time of each airdrop
  const componentStates = (
    await Promise.all(
      airdrop_ids_with_state_version.map((id_with_state) =>
        gatewayApi.state.getEntityDetailsVaultAggregated(
          [STAKE_COMPONENT_ADDRESS],
          undefined,
          { state_version: id_with_state.state_version }
        )
      )
    )
  ).flat();
  const tokenPrices = TokenCacheService.allTokens;

  // calculate the airdrop token amount and add coins metadata into airdrop data
  const filteredAirdrops = airdrop_ids_with_state_version
    .map((idState, index) => {
      const airdropId = idState.airdrop_id;
      const thisCompState = componentStates[index];
      const totalStakeShare = thisCompState.details.state.fields[6].value;
      const tokenAddressOfThisAirdrop = airdropData[airdropId].tokenAddress;

      totalStakeShareWhileAirdrop[airdropId] = totalStakeShare;

      const airdropAmount = new Decimal(totalStakeShare)
        .mul(airdropData[airdropId].perShare)
        .toString();

      const tokenPrice =
        tokenPrices[tokenAddressOfThisAirdrop]?.tokenPriceUSD || 0;

      const airdropWorthInUSD = new Decimal(airdropAmount).mul(tokenPrice);

      // if (airdropWorthInUSD.greaterThanOrEqualTo(1)) {
      //   airdropData[airdropId] = {
      //     airdropWorthInUSD: airdropWorthInUSD.toString(),
      //     airdropAmount: new Decimal(totalStakeShare)
      //       .mul(airdropData[airdropId].perShare)
      //       .toString(),
      //     ...coins_metadata[tokenAddressOfThisAirdrop],
      //   };
      // }
      const airdropDetails = {
        airdropAmount,
        worthInUSD: airdropWorthInUSD.toFixed(2),
        ...coins_metadata[tokenAddressOfThisAirdrop],
      };

      return { airdropId, ...airdropDetails };
    })
    .filter((airdrop) => new Decimal(airdrop.worthInUSD).gte(1));

  // If we have less than 5 airdrops, fetch more using next_cursor
  if (filteredAirdrops.length < 5 && new_next_cursor && new_ledger_state) {
    const additionalAirdrops = await getLatestAirdrops(
      new_next_cursor,
      new_ledger_state
    );
    return filteredAirdrops.concat(additionalAirdrops).slice(0, 5); // Return only 5 airdrops
  }

  // console.log("filteredAirdrops", filteredAirdrops.slice(0, 5));
  dispatch(setLatestAirdrops(Object.values(filteredAirdrops.slice(0, 5))));
  return filteredAirdrops.slice(0, 5);
};
