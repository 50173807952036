import React from "react";

const Button = ({ onClick, className, disabled, content }) => {
  return (
    <div
      onClick={onClick}
      className={"bg-green-500 p-[10px] text-center font-bold hover:cursor-pointer hover:bg-opacity-80 "
        .concat(disabled ? "bg-opacity-50 pointer-events-none " : " ")
        .concat(className)}
    >
      {content}
    </div>
  );
};

export default Button;
