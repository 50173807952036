import { useMemo } from "react";
import {
  formatDollarAmount,
  formatTokenAmount,
  generateExplorerTxLink,
} from "../Utils/formatter";

export const BaseToast = ({
  heading,
  subHeading,
  explorerLink,
  txId,
  twSubHeadingClass = "",
  twHeadingClass = "",
  logoUrl,
  infoUrl,
  tokenSymbol,
}) => {
  return (
    <div className="flex items-start gap-3 ">
      {logoUrl && (
        <img
          src={logoUrl}
          alt="token"
          className="w-20 h-20 rounded-full self-center"
        />
      )}
      <div>
        <p className={"font-extrabold " + twHeadingClass}>{heading}</p>
        {subHeading ? (
          <p className={"text-sm " + twSubHeadingClass}>{subHeading}</p>
        ) : null}
        {txId ? (
          <p
            className="mt-2 text-sm font-extrabold w-fit text-base-content cursor-pointer"
            onClick={() =>
              window.open(
                explorerLink ?? generateExplorerTxLink(txId),
                "_blank"
              )
            }
            style={{ borderBottom: "1px solid #293a24" }}
          >
            View transaction
          </p>
        ) : infoUrl ? (
          <p
            className="mt-2 text-sm font-extrabold w-fit text-base-content cursor-pointer"
            onClick={() => window.open(infoUrl, "_blank")}
            style={{ borderBottom: "1px solid #293a24" }}
          >
            Checkout {tokenSymbol} Website
          </p>
        ) : null}
      </div>
    </div>
  );
};

export const AirdropAlertToast = ({
  amount,
  tokenName,
  logoUrl,
  infoUrl,
  worthInUSD,
}) => {
  const amountInUSD = useMemo(() => {
    const num = Number(worthInUSD);
    return num === 0 ? undefined : formatDollarAmount(num);
  }, [worthInUSD]);
  return (
    <BaseToast
      heading="🚨Airdrop Alert🚨"
      subHeading={
        <>
          <span className="font-semibold mr-1">
            {formatTokenAmount(amount)} ${tokenName}{" "}
            {amountInUSD ? `(${amountInUSD})` : ""}
          </span>
          has been airdropped to the FOMO stakers. Check your airdrop now 👇.
        </>
      }
      twHeadingClass=""
      twSubHeadingClass=""
      logoUrl={logoUrl}
      infoUrl={infoUrl}
      tokenSymbol={tokenName}
    />
  );
};
