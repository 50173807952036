import React, { useEffect, useState } from "react";
import { Modal } from "flowbite-react";
import { dispatch, useSelector } from "../../Store";
import { formatTokenAmount } from "../../Utils/formatter";
import { setIsMigrateModalShown } from "../../Store/Reducers/session";
import { migrate, migrateoldFomoToken } from "../../Utils/txSenders";
import NftSelectionModal from "./nftSelection";

const OldAssetsAlert = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const oldFomoBalance = useSelector((state) => state.session.oldFomoBalance);
  const oldFomoNfts = useSelector((state) => state.session.oldFomoNfts);
  const successTx = useSelector((state) => state.session.successTx);
  const walletAddress = useSelector((state) => state.app.walletAddress);
  const isMigrateModalShown = useSelector(
    (state) => state.session.isMigrateModalShown
  );

  const [selectedNft, setSelectedNft] = useState([]);

  const [isNftSelectionModalOpen, setIsNftSelectionModalOpen] = useState(false);

  useEffect(() => {
    if (
      !isMigrateModalShown &&
      (+oldFomoBalance > 0 || oldFomoNfts.length > 0)
    ) {
      dispatch(setIsMigrateModalShown(true));
      setIsModalOpen(true);
    }
  }, [oldFomoBalance, isMigrateModalShown, oldFomoNfts]);

  useEffect(() => {
    if (Boolean(successTx)) {
      setSelectedNft([]);
    }
  }, [successTx]);

  useEffect(() => {
    if (!Boolean(+oldFomoBalance) && oldFomoNfts.length === 0) {
      setIsModalOpen(false);
    }
  }, [oldFomoBalance, oldFomoNfts.length]);

  return (
    <Modal
      show={isModalOpen}
      size="md"
      onClose={() => setIsModalOpen(false)}
      popup
      theme={{
        content: {
          base: "relative w-full p-4 h-auto !max-w-lg",
          inner:
            "relative flex max-h-[70dvh] flex-col rounded-lg bg-[#232325] bg-opacity-30 backdrop-blur-lg border border-gray-500",
        },
      }}
      className="backdrop-blur-sm"
    >
      <Modal.Header />
      <Modal.Body>
        <div className="text-center flex flex-col items-center justify-center">
          <img
            src="/images/traced-fomo-right.png"
            alt="logo"
            className="w-32"
          />
          <p className="text-white font-bold my-3 text-lg">
            🚨 PLEASE MIGRATE YOUR FOMO 🚨
          </p>
          <p className="mb-1 font-normal text-white">
            We have initiated the new and improved FOMO and fomostaking. Please
            migrate your old FOMO tokens over to the new version.
          </p>
          {+oldFomoBalance > 0 && (
            <>
              <p className="font-semibold text-white self-start">
                Old FOMO Tokens:
              </p>
              <div className="font-semibold text-white self-start bg-[#232325] bg-opacity-60  w-full px-2 py-2 rounded">
                <p className="font-normal min-w-max w-full mb-2">
                  Balance: {formatTokenAmount(+oldFomoBalance)} (old FOMO)
                </p>
                <button
                  onClick={() =>
                    migrateoldFomoToken(walletAddress, oldFomoBalance)
                  }
                  className="bg-green-500 rounded px-2 py-0.5 min-w-max w-full"
                >
                  Migrate to new FOMO
                </button>
              </div>
            </>
          )}
          {oldFomoNfts.length > 0 && (
            <>
              <div className="flex items-center justify-center font-semibold text-white self-start mt-3">
                <p>Old staked FOMO NFTs: </p>
              </div>
              <div className="font-semibold text-white self-start bg-[#232325] bg-opacity-60  w-full flex flex-col items-center justify-center px-2 py-2 rounded">
                <div
                  onClick={() => setIsNftSelectionModalOpen(true)}
                  className="border border-gray-500 rounded hover:border-green-500 cursor-pointer px-3 py-0.5 min-w-max mb-2"
                >
                  {selectedNft.length > 0 ? (
                    <div className="flex items-center font-normal justify-center">
                      <img
                        className="rounded-full w-5 h-5 bg-cover bg-center bg-no-repeat"
                        src={selectedNft[0].nftImage}
                        alt="icon"
                        onError={(e) => (e.target.src = "/images/logo.jpg")}
                      />
                      <p className="ml-2">id:{selectedNft[0].id}</p>
                      <p className="ml-2" title={selectedNft[0].amount_staked}>
                        ({formatTokenAmount(+selectedNft[0].amount_staked)}{" "}
                        FOMO)
                      </p>
                    </div>
                  ) : (
                    <div className="flex items-center justify-between">
                      {"Select old FOMO NFT"}{" "}
                      <img
                        src="images/icon/chevron-down.svg"
                        className="text-gray-500"
                        alt="info"
                      />
                    </div>
                  )}
                </div>

                <button
                  onClick={() => migrate(walletAddress, selectedNft[0].id)}
                  className=" bg-green-500 rounded px-2 py-0.5 w-full"
                  disabled={selectedNft.length === 0}
                >
                  Migrate to new staked NFT
                </button>
                <div className="flex items-center justify-center mt-1">
                  <img
                    src="images/icon/info.svg"
                    className="w-4 mr-1"
                    alt="info"
                  />
                  <p className="text-xs font-normal">
                    unstake old FOMO {"->"} migrate {"->"} stake new FOMO
                  </p>
                </div>
              </div>
            </>
          )}
          <p className="font-normal text-white mt-4">
            Unable to migrate? contact us on {""}
            <a href="https://t.me/fomoradix" className="underline">
              our telegram.
            </a>
          </p>
          <NftSelectionModal
            isModalOpen={isNftSelectionModalOpen}
            onClose={() => setIsNftSelectionModalOpen(false)}
            nftsArray={oldFomoNfts}
            setSelectedNfts={setSelectedNft}
            selectedNfts={selectedNft}
            onNftSelect={() => setIsNftSelectionModalOpen(false)}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default OldAssetsAlert;
