import { store } from "../Store";
import { incrementSuccessTx } from "../Store/Reducers/session";
import {
  FOMO_RESOURCE_ADDRESS,
  OLD_FOMO_RESOURCE_ADDRESS,
  OLD_STAKED_FOMO_ADDRESS,
  OLD_STAKE_COMPONENT_ADDRESS,
  STAKED_FOMO_ADDRESS,
  STAKE_COMPONENT_ADDRESS,
} from "../Constants/addresses";
import { getRdt } from "../subs";
import { generateRandomNonce } from "@radixdlt/radix-engine-toolkit";
import { MAX_AIRDROP_LIMIT } from "../Constants/misc";
import { gatewayApi } from "../Constants/endpoints";

export const Stake = async (inputStakingAmount, accountAddress) => {
  const rdt = getRdt();
  try {
    if (inputStakingAmount > 0) {
      const stake_manefist = `CALL_METHOD Address("${accountAddress}") "withdraw" Address("${FOMO_RESOURCE_ADDRESS}") Decimal("${inputStakingAmount}") ; TAKE_ALL_FROM_WORKTOP Address("${FOMO_RESOURCE_ADDRESS}") Bucket("tokens") ; CALL_METHOD Address("${STAKE_COMPONENT_ADDRESS}") "add_stake" Bucket("tokens") ; CALL_METHOD Address("${accountAddress}") "deposit_batch" Expression("ENTIRE_WORKTOP") ;`;
      const result = await rdt.walletApi.sendTransaction({
        transactionManifest: stake_manefist,
      });
      if (result.isErr()) {
        throw result.error;
      }
      // const transactionIntentHash = result.transactionIntentHash;
      // console.log("stakingtransactionIntentHash", transactionIntentHash);
      store.dispatch(incrementSuccessTx());
    } else {
      alert("Input Amount To Stake");
    }
  } catch (error) {
    console.log("Unable to send Transaction", error);
  }
};

export const unStake = async (selectedNftID, accountAddress, isClaim) => {
  const rdt = getRdt();
  try {
    if (selectedNftID) {
      const unstake_manefist = `CALL_METHOD Address("${accountAddress}") "withdraw_non_fungibles" Address("${STAKED_FOMO_ADDRESS}") Array<NonFungibleLocalId>(NonFungibleLocalId("#${selectedNftID}#")) ; TAKE_ALL_FROM_WORKTOP Address("${STAKED_FOMO_ADDRESS}") Bucket("tokens") ; CALL_METHOD Address("${STAKE_COMPONENT_ADDRESS}") "remove_stake" Bucket("tokens") Array<Address>() ${MAX_AIRDROP_LIMIT}u64 ${isClaim} ; CALL_METHOD Address("${accountAddress}") "deposit_batch" Expression("ENTIRE_WORKTOP") ;`;
      const result = await rdt.walletApi.sendTransaction({
        transactionManifest: unstake_manefist,
      });
      if (result.isErr()) {
        throw result.error;
      }
      // const transactionIntentHash = result.value.transactionIntentHash;
      // console.log("transactionIntentHash", transactionIntentHash);
      store.dispatch(incrementSuccessTx());
    } else {
      alert("Select NFT To Unstake");
    }
  } catch (error) {
    console.log("Unable to send Transaction", error);
  }
};

export const airdrop = async (
  inputAirdropAmount,
  selectedTokenIDToAirdrop,
  accountAddress
) => {
  const rdt = getRdt();
  try {
    if (inputAirdropAmount > 0 && selectedTokenIDToAirdrop.length > 0) {
      const airdrop_manefist = `CALL_METHOD Address("${accountAddress}") "withdraw" Address("${selectedTokenIDToAirdrop}") Decimal("${inputAirdropAmount}") ; TAKE_ALL_FROM_WORKTOP Address("${selectedTokenIDToAirdrop}") Bucket("tokens") ; CALL_METHOD Address("${STAKE_COMPONENT_ADDRESS}") "airdrop" Bucket("tokens") ;`;

      const result = await rdt.walletApi.sendTransaction({
        transactionManifest: airdrop_manefist,
      });
      if (result.isErr()) {
        throw result.error;
      }
      // const transactionIntentHash = result.value.transactionIntentHash;
      // console.log("stakingtransactionIntentHash", transactionIntentHash);
      store.dispatch(incrementSuccessTx());
    } else {
      alert("Select a Token and Input Amount To Airdrop");
    }
  } catch (error) {
    console.log("Unable to send Transaction", error);
  }
};

export const simulateTx = async (manifest) => {
  const latestLedgerState =
    await gatewayApi.transaction.innerClient.transactionConstruction();

  const nonce = await generateRandomNonce();

  const preview = await gatewayApi.transaction.innerClient.transactionPreview({
    transactionPreviewRequest: {
      manifest,
      nonce,
      tip_percentage: 0,
      flags: {
        use_free_credit: true,
        assume_all_signature_proofs: true,
        skip_epoch_check: true,
      },
      start_epoch_inclusive: latestLedgerState.ledger_state.epoch,
      end_epoch_exclusive: latestLedgerState.ledger_state.epoch + 1,
      signer_public_keys: [],
    },
  });
  console.log("preview", preview);
  return preview;
};

export const simulateUnstakeTx = async (
  accountAddress,
  selectedNftID,
  isClaim
) => {
  const unstake_manefist = `CALL_METHOD Address("${accountAddress}") "withdraw_non_fungibles" Address("${STAKED_FOMO_ADDRESS}") Array<NonFungibleLocalId>(NonFungibleLocalId("#${selectedNftID}#")) ; TAKE_ALL_FROM_WORKTOP Address("${STAKED_FOMO_ADDRESS}") Bucket("tokens") ; CALL_METHOD Address("${STAKE_COMPONENT_ADDRESS}") "remove_stake" Bucket("tokens") Array<Address>() ${MAX_AIRDROP_LIMIT}u64 ${isClaim} ; CALL_METHOD Address("${accountAddress}") "deposit_batch" Expression("ENTIRE_WORKTOP") ;`;

  await simulateTx(unstake_manefist);
};

export const simulateMigrateTx = async () => {
  const manifest =
    'CALL_METHOD Address("account_rdx16xggzlrjtq2t258jhrr76aua7x9mjqkle3e75ltc9mmkywupllq6h3") "withdraw_non_fungibles" Address("resource_rdx1nf4djwvfsel2axsggh6k6x8a2cuycayn08c97z5u34uuegtdan5d7z") Array<NonFungibleLocalId>(NonFungibleLocalId("#138#")) ; TAKE_ALL_FROM_WORKTOP Address("resource_rdx1nf4djwvfsel2axsggh6k6x8a2cuycayn08c97z5u34uuegtdan5d7z") Bucket("old_staked_fomo") ; CALL_METHOD Address("component_rdx1czhz5y9eucherc7gvwhsjvdu6yeywh9pdtrulu5n76mt0x8llf536e") "remove_stake" Bucket("old_staked_fomo") ; TAKE_ALL_FROM_WORKTOP Address("resource_rdx1t4a42s786rs0nz6lv3skg2kvk94alxrpd5v73q9e7jrt33fsalcg0m") Bucket("old_fomo") ; CALL_METHOD Address("component_rdx1cp62jmvswv5esyxw7lttvvtfwu397z6m9umzt5lamydum86cvcpaan") "swap" Bucket("old_fomo") ; TAKE_ALL_FROM_WORKTOP Address("resource_rdx1t5l954908vmg465pkj7j37z0fn4j33cdjt2g6czavjde406y4uxdy9") Bucket("new_fomo") ; CALL_METHOD Address("component_rdx1cr6lel0w3ktv0hww999uqpce5r2uzy24l5083dpg57n8xg66cerkcx") "add_stake" Bucket("new_fomo") ; CALL_METHOD Address("account_rdx16xggzlrjtq2t258jhrr76aua7x9mjqkle3e75ltc9mmkywupllq6h3") "deposit_batch" Expression("ENTIRE_WORKTOP") ;';
  await simulateTx(manifest);
};

export const simulateOldUnstakeTx = async (accountAddress) => {
  const manifest = `CALL_METHOD Address("${accountAddress}") "withdraw_non_fungibles" Address("${OLD_STAKED_FOMO_ADDRESS}") Array<NonFungibleLocalId>(NonFungibleLocalId("#138#")) ; TAKE_ALL_FROM_WORKTOP Address("${OLD_STAKED_FOMO_ADDRESS}") Bucket("tokens") ; CALL_METHOD Address("${OLD_STAKE_COMPONENT_ADDRESS}") "remove_stake" Bucket("tokens") ; CALL_METHOD Address("${accountAddress}") "deposit_batch" Expression("ENTIRE_WORKTOP") ;`;
  await simulateTx(manifest);
};

export const migrate = async (accountAddress, oldNFTId) => {
  const migrateManifest = `CALL_METHOD Address("${accountAddress}") "withdraw_non_fungibles" Address("${OLD_STAKED_FOMO_ADDRESS}") Array<NonFungibleLocalId>(NonFungibleLocalId("#${oldNFTId}#")) ; TAKE_ALL_FROM_WORKTOP Address("${OLD_STAKED_FOMO_ADDRESS}") Bucket("old_staked_fomo") ; CALL_METHOD Address("${OLD_STAKE_COMPONENT_ADDRESS}") "remove_stake" Bucket("old_staked_fomo") ; TAKE_ALL_FROM_WORKTOP Address("${OLD_FOMO_RESOURCE_ADDRESS}") Bucket("old_fomo") ; CALL_METHOD Address("component_rdx1cp62jmvswv5esyxw7lttvvtfwu397z6m9umzt5lamydum86cvcpaan") "swap" Bucket("old_fomo") ; TAKE_ALL_FROM_WORKTOP Address("${FOMO_RESOURCE_ADDRESS}") Bucket("new_fomo") ; CALL_METHOD Address("${STAKE_COMPONENT_ADDRESS}") "add_stake" Bucket("new_fomo") ; CALL_METHOD Address("${accountAddress}") "deposit_batch" Expression("ENTIRE_WORKTOP") ;`;

  try {
    const rdt = getRdt();
    const result = await rdt.walletApi.sendTransaction({
      transactionManifest: migrateManifest,
    });
    if (result.isErr()) {
      throw result.error;
    }
    // const transactionIntentHash = result.value.transactionIntentHash;
    // console.log("stakingtransactionIntentHash", transactionIntentHash);
    store.dispatch(incrementSuccessTx());
  } catch (error) {
    console.log("Unable to send Transaction", error);
  }
};

export const migrateoldFomoToken = async (accountAddress, oldFomoBalance) => {
  const migrateManifest = `CALL_METHOD Address("${accountAddress}") "withdraw" Address("${OLD_FOMO_RESOURCE_ADDRESS}") Decimal("${oldFomoBalance}") ; TAKE_FROM_WORKTOP Address("${OLD_FOMO_RESOURCE_ADDRESS}") Decimal("${oldFomoBalance}") Bucket("old_token") ; CALL_METHOD Address("component_rdx1cp62jmvswv5esyxw7lttvvtfwu397z6m9umzt5lamydum86cvcpaan") "swap" Bucket("old_token") ; CALL_METHOD Address("${accountAddress}") "deposit_batch" Expression("ENTIRE_WORKTOP") ;`;

  try {
    const rdt = getRdt();
    const result = await rdt.walletApi.sendTransaction({
      transactionManifest: migrateManifest,
    });
    if (result.isErr()) {
      throw result.error;
    }
    // const transactionIntentHash = result.value.transactionIntentHash;
    // console.log("stakingtransactionIntentHash", transactionIntentHash);
    store.dispatch(incrementSuccessTx());
  } catch (error) {
    console.log("Unable to send Transaction", error);
  }
};

export const sendGiftWhileUnstakeOrClaim = async (
  accountAddress,
  selectedNftID,
  receiverAddress,
  isClaim,
  giftTokensAddresses
) => {
  const rdt = getRdt();
  try {
    if (selectedNftID) {
      const sendGiftManifest = `
    CALL_METHOD
      Address("${accountAddress}")
      "withdraw_non_fungibles"
      Address("${STAKED_FOMO_ADDRESS}")
      Array<NonFungibleLocalId>(NonFungibleLocalId("#${selectedNftID}#"))
    ;
    TAKE_ALL_FROM_WORKTOP
      Address("${STAKED_FOMO_ADDRESS}")
      Bucket("tokens")
    ;
    CALL_METHOD
      Address("${STAKE_COMPONENT_ADDRESS}")
      "remove_stake"
      Bucket("tokens")
      Array<Address>()
      ${MAX_AIRDROP_LIMIT}u64
      ${isClaim}
    ;
    ${giftTokensAddresses
      .map(
        (giftTokensAddress, index) => `
      TAKE_ALL_FROM_WORKTOP
        Address("${giftTokensAddress}")
        Bucket("giftTokens${index}")
      `
      )
      .join(";")}
    ;
    ${giftTokensAddresses
      .map(
        (giftTokensAddress, index) => `
      CALL_METHOD
        Address("${receiverAddress}")
        "try_deposit_or_refund"
        Bucket("giftTokens${index}")
        None
      `
      )
      .join(";")}
    ;
    
    CALL_METHOD
      Address("${accountAddress}")
      "deposit_batch"
      Expression("ENTIRE_WORKTOP")
    ;
  `;

      console.log("sendGiftManifest", sendGiftManifest);
      const result = await rdt.walletApi.sendTransaction({
        transactionManifest: sendGiftManifest,
      });
      if (result.isErr()) {
        throw result.error;
      }
      store.dispatch(incrementSuccessTx());
    } else {
      alert("Select NFT To Unstake");
    }
  } catch (error) {
    console.log("Unable to send Transaction", error);
  }
};
