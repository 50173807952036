import React, { useMemo } from "react";
import {
  formatDollarAmount,
  formatTokenAmount,
} from "../../../Utils/formatter";

const TokenShareTile = ({ tokenData }) => {
  const shareInUSD = useMemo(() => {
    const num = Number(tokenData.shareInUSD);
    return num === 0 ? undefined : formatDollarAmount(tokenData.shareInUSD);
  }, [tokenData.shareInUSD]);
  return (
    <div
      className="text-white flex items-center justify-start px-3 py-2 gap-3 w-full  bg-[#232325] bg-opacity-30 backdrop-blur-lg rounded-lg"
      style={{ zIndex: 2 }}
    >
      <img
        src={tokenData.icon_url}
        alt="token logo"
        className="w-10 h-10 rounded-full"
      />
      <div className="flex items-center justify-between w-full ">
        <div className="max-w-[180px] flex flex-col justify-between self-stretch">
          <p className="text-left font-semibold text-lg">{tokenData.symbol}</p>
          <p className="text-left opacity-65 ellipses w-full !text-md">
            {tokenData.name}
          </p>
        </div>

        <div className="flex flex-col items-end justify-between">
          <p className="cursor-pointer text-xl z-100" title={tokenData.share}>
            {formatTokenAmount(tokenData.share)}
          </p>
          {shareInUSD && (
            <p
              className="cursor-pointer opacity-65 text-md z-100"
              title={tokenData.shareInUSD}
            >
              {shareInUSD}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default TokenShareTile;
