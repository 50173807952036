import { Modal } from "flowbite-react";
import React from "react";

import CardsGroup from "../cardsGroup";

const TokenSelectionModal = ({
  isModalOpen,
  onClose,
  tokensArray,
  selectedCards,
  setSelectedCards,
  onCardSelect,
}) => {
  return (
    <Modal
      show={isModalOpen}
      size="md"
      onClose={onClose}
      popup
      theme={{
        content: {
          base: "relative w-full px-2 h-auto !max-w-xl ",
          inner:
            "relative flex max-h-[70dvh] flex-col rounded-lg bg-[#232325] bg-opacity-30 backdrop-blur-lg border border-gray-500",
        },
      }}
      className="backdrop-blur-sm"
    >
      <Modal.Header />
      <Modal.Body>
        <div className="text-center flex w-full">
          <CardsGroup
            cardsArray={tokensArray}
            heading_key="symbol"
            img_key="icon_url"
            value_key="balance"
            tokenAddress_key="address"
            isBalance
            selectedCards={selectedCards}
            setSelectedCards={setSelectedCards}
            enableCardSelection
            onCardSelect={onCardSelect}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default TokenSelectionModal;
