import React, { useEffect, useState } from "react";
import Button from "../../../Components/button";
import { unStake } from "../../../Utils/txSenders";
import { dispatch, useSelector } from "../../../Store";
import { formatTokenAmount } from "../../../Utils/formatter";
import NftSelectionModal from "../../../Components/Modals/nftSelection";
import { setSelectedUnstakeNft } from "../../../Store/Reducers/session";

const UnstakeSection = () => {
  const userFomoNfts = useSelector((state) => state.session.userFomoNfts);
  const walletAddress = useSelector((state) => state.app.walletAddress);
  const successTx = useSelector((state) => state.session.successTx);
  const selectedUnstakeNft = useSelector(
    (state) => state.session.selectedUnstakeNft
  );
  const [isNftSelectionModalOpen, setIsNftSelectionModalOpen] = useState(false);

  useEffect(() => {
    if (Boolean(successTx)) {
      dispatch(setSelectedUnstakeNft([]));
    }
  }, [successTx]);

  return (
    <div className="bg-[#0c0c0c] bg-opacity-60 px-[20px] mt-[5px] border-l-2 py-2 border-green-500">
      <div className="flex text-left">
        <div
          onClick={() => setIsNftSelectionModalOpen(true)}
          className="border border-gray-500 w-full sm:w-2/3 bg-[#232325] bg-opacity-60 p-[10px]  outline-none rounded-t-lg sm:rounded-l-lg sm:rounded-tr-none cursor-pointer hover:border-green-500"
        >
          {selectedUnstakeNft.length > 0 ? (
            <div className="flex items-center">
              <img
                className="rounded-full w-5 h-5 bg-cover bg-center bg-no-repeat"
                src={selectedUnstakeNft[0].nftImage}
                alt="icon"
                onError={(e) => (e.target.src = "/images/logo.jpg")}
              />
              <p className="ml-2">id:{selectedUnstakeNft[0].id}</p>
              <p className="ml-2" title={selectedUnstakeNft[0].amount_staked}>
                ({formatTokenAmount(+selectedUnstakeNft[0].amount_staked)} FOMO)
              </p>
            </div>
          ) : (
            <div className="flex items-center justify-between">
              {"Select a NFT"}{" "}
              <img
                src="images/icon/chevron-down.svg"
                className="text-gray-500"
                alt="info"
              />
            </div>
          )}
        </div>
        <Button
          onClick={() =>
            unStake(selectedUnstakeNft[0].id, walletAddress, false)
          }
          className={"w-1/3 hidden sm:block border-r border-r-black"}
          content={"UNSTAKE"}
          disabled={selectedUnstakeNft.length === 0}
        />
        <Button
          onClick={() => unStake(selectedUnstakeNft[0].id, walletAddress, true)}
          className={"w-1/3 hidden sm:block rounded-r-lg"}
          content={"Claim Rewards"}
          disabled={selectedUnstakeNft.length === 0}
        />
      </div>
      <div className="flex sm:hidden items-center">
        <Button
          onClick={() =>
            unStake(selectedUnstakeNft[0].id, walletAddress, false)
          }
          className={"flex-1 rounded-bl-lg border-r border-r-black"}
          content={"UNSTAKE"}
          disabled={selectedUnstakeNft.length === 0}
        />
        <Button
          onClick={() => unStake(selectedUnstakeNft[0].id, walletAddress, true)}
          className={"flex-1 rounded-br-lg "}
          content={"Claim Rewards"}
          disabled={selectedUnstakeNft.length === 0}
        />
      </div>

      <NftSelectionModal
        isModalOpen={isNftSelectionModalOpen}
        onClose={() => setIsNftSelectionModalOpen(false)}
        nftsArray={userFomoNfts}
        setSelectedNfts={(nft) => dispatch(setSelectedUnstakeNft(nft))}
        selectedNfts={selectedUnstakeNft}
        onNftSelect={() => setIsNftSelectionModalOpen(false)}
      />
    </div>
  );
};

export default UnstakeSection;
