import { Modal } from "flowbite-react";
import React from "react";
import CardsGroup from "../cardsGroup";

const NftSelectionModal = ({
  isModalOpen,
  onClose,
  nftsArray,
  selectedNfts,
  setSelectedNfts,
  onNftSelect,
}) => {
  return (
    <Modal
      show={isModalOpen}
      size="md"
      onClose={onClose}
      popup
      theme={{
        content: {
          base: "relative w-full px-2 h-auto !max-w-xl",
          inner:
            "relative flex max-h-[70dvh] flex-col rounded-lg bg-[#232325] bg-opacity-30 backdrop-blur-lg border border-gray-500",
        },
      }}
      className="backdrop-blur-sm"
    >
      <Modal.Header />
      <Modal.Body>
        <div className="text-center flex w-full">
          <CardsGroup
            cardsArray={nftsArray}
            heading_key="id"
            img_key="nftImage"
            value_key="amount_staked"
            selectedCards={selectedNfts}
            setSelectedCards={setSelectedNfts}
            enableCardSelection
            onCardSelect={onNftSelect}
            isBalance
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default NftSelectionModal;
