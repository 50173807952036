import { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { Header } from "./Layout/Header";
import { StakingPage } from "./Pages/Staking";
import { initializeSubscriptions, unsubscribeAll } from "./subs";
import NftPage from "./Pages/NftData";
import "./App.css";
import Listeners from "./Components/listeners";
import TokenCacheService from "./Classes/tokenCache";
import { useSelector } from "./Store";
import { shouldFetchTokenList } from "./Utils/helpers";
import GlobalModals from "./Components/Modals/globalModals";

function App() {
  const lastTokensUpdated = useSelector((state) => state.app.lastTokensUpdated);

  useEffect(() => {
    initializeSubscriptions();
    (async () => {
      await TokenCacheService.loadTokensList();
      if (await shouldFetchTokenList(lastTokensUpdated)) {
        await TokenCacheService.fetchTokensList();
        await TokenCacheService.loadTokensList();
      }
    })();
    return () => {
      unsubscribeAll();
    };
  }, []);
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Listeners />
        <GlobalModals />
        <main className="relative w-full ">
          <Routes>
            <Route index path="/" element={<StakingPage />} />
            <Route path="/nft" element={<NftPage />} />
            <Route path="/nft/:id" element={<NftPage />} />
          </Routes>
        </main>
      </BrowserRouter>
    </div>
  );
}

export default App;
