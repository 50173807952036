import React from "react";
import OldAssetsAlert from "./oldAssetsAlert";
import SendAirdropGift from "./sendAirdropGift";

const GlobalModals = () => {
  return (
    <>
      <OldAssetsAlert />
      <SendAirdropGift />
    </>
  );
};

export default GlobalModals;
